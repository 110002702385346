<template>
  <div>
    <CompleteTable
      v-if="!loading"
      :sorted-field="sortedField"
      :data="applicants"
      :sort-mapping="sortMapping"
      :title="'applicants'"
      :total="applicantsTotal"
      :fetch-path="'convo/fetchApplicants'"
      :export-path="'convo/exportApplicantsRound0'"
      :filter-path="'-'"
      :actions="status != 7 || customActions"
      @selectedItems="saveSelectedItems"
      @appliedFilters="appliedFilters"
    >
      <template v-slot="{field}">
        <td
          class="text-end"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <i data-feather="more-vertical" />
            </template>
            <div
              class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
            >
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormData', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form data
              </b-dropdown-item>
              <b-dropdown-item @click="selectedItem = field, $store.dispatch('modals/toggleFormEvaluation', true)">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View form evaluation
              </b-dropdown-item>
              <b-dropdown-item @click="$store.dispatch('modals/toggleUserTraces', true), traceUser = field">
                <i
                  class="me-50"
                  data-feather="eye"
                /> View traces
              </b-dropdown-item>
            </div>
          </b-dropdown>
          <a
            href=""
            class="btn btn-icon btn-light-secondary d-block d-sm-none"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasBottom"
          ><i
            data-feather="more-vertical"
          /></a>
        </td>
      </template>
      <template #customfilters>
        <div
          class="mb-1"
        >
          <label
            for="nameseniorcall"
            class="form-label"
          >Candidate</label>
          <v-select
            label="full_name"
            :filter="customFilter"
            :options="applicants"
            :get-option-key="option => option.id"
            :placeholder="'Type 3 or more characters to search...'"
            @search="onSearch({ candidate: $event }, 'convo/fetchApplicants')"
            @input="saveFilter($event, 'candidate')"
          />
          <hr>
        </div>
        <div
          v-if="!status"
          class="mb-1"
        >
          <label
            for=""
            class="form-label"
          >Status</label>
          <select
            id=""
            name=""
            class="form-select"
            @change="saveFilter($event.target.value, 'status')"
          >
            <option value="0">
              All applicants
            </option>
            <option value="3">
              Not eligible
            </option>
            <option value="4">
              1st round
            </option>
            <option value="5">
              2nd round
            </option>
            <option value="6">
              Ranked
            </option>
            <option value="7">
              Offered
            </option>
            <option value="8">
              Position accepted
            </option>
          </select>
        </div>
        <div
          class="mb-1"
        >
          <label
            for="nameseniorcall"
            class="form-label"
          >Area</label>
          <v-select
            label="name"
            :options="areas"
            :get-option-key="option => option.id"
            @input="saveFilter($event, 'area')"
            @search="onSearch({ name: $event }, 'areas/filter')"
          />
        </div>
      </template>
      <template #info-sheet-item>
        <div class="offcanvas-body offcanvas-body--view">

          <h4 class="mb-2">
            <strong class="text-success">{{ selectedItems.length }}</strong> selected applicants
          </h4>

          <ul
            v-if="customActions"
            id="basic-list-group"
            class="list-group"
          >
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction1"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="7"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction1"
                    >Move to Offered</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction2"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="13"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction2"
                    >Move to Position declined</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction3"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="10"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction3"
                    >Move to Position rejected</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction4"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="12"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction4"
                    >Move to Position accepted - Convert to ICREA</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul
            v-if="!customActions"
            id="basic-list-group"
            class="list-group"
          >
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction5"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="3"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction5"
                    >Move to Not elegible</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction10"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="2"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction10"
                    >Move to round 0</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction6"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="4"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction6"
                    >Move to round 1</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction7"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="5"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction7"
                    >Move to round 2</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction8"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="6"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction8"
                    >Move to ranked</label>
                  </div>
                </div>
              </div>
            </li>
            <li class="list-group-item draggable">
              <div class="d-flex">
                <div class="more-info">
                  <div class="form-check">
                    <input
                      id="tableAction1"
                      v-model="actions.moveRound"
                      type="radio"
                      class="form-check-input"
                      name="actions"
                      value="7"
                    >
                    <label
                      class="form-check-label"
                      for="tableAction1"
                    >Move to offered</label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="offcanvas-footer mt-auto">
          <a
            href="#"
            title="apply actions"
            class="btn btn-primary mb-1 d-grid w-100"
            @click="applyActions"
          >Apply actions</a>
        </div>
      </template>
    </CompleteTable>
    <ShowFormEvaluation :selected="selectedItem" />
    <ManageUserTraces :user="traceUser" />
    <ShowFormData :selected="selectedItem" />
    <div
      v-if="loading"
      id="loading-bg"
    >
      <div
        class="loading"
      >
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import CompleteTable from '@/views/back/partials/components/CompleteTable.vue'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import ManageUserTraces from '@/views/back/partials/offcanvas/ManageUserTraces.vue'
import vSelect from 'vue-select'
import ShowFormEvaluation from './ShowFormEvaluation.vue'
import ShowFormData from './ShowFormData.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    ShowFormEvaluation,
    ManageUserTraces,
    ShowFormData,
    vSelect,
  },
  props: {
    status: { type: Number, required: false, default: () => null },
    customActions: { type: Boolean, required: false, default: () => false },
  },
  data() {
    return {
      selectedItem: {},
      traceUser: {},
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Applicant ID',
          checked: true,
          order: 2,
        },
        {
          name: 'Area',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Status',
          checked: true,
          order: 5,
        },
        {
          name: 'AVG grade',
          checked: true,
          order: 6,
        },
        {
          name: 'AVG normalized',
          checked: true,
          order: 7,
        },
        {
          name: 'Evaluators',
          checked: true,
          order: 8,
        },
        {
          name: 'Feedback editor',
          checked: true,
          order: 9,
        },
        {
          name: 'ERC',
          checked: true,
          order: 10,
        },
        {
          name: 'F.A.',
          checked: true,
          order: 11,
        },
        {
          name: 'Traces',
          checked: true,
          order: 12,
        },
      ],
      sortMapping: {
        Name: 'full_name',
        'Applicant ID': 'applicant_convo_id',
        Area: 'area_code',
        Institution: 'institution_name',
        Status: 'status.status_table',
        'AVG grade': 'avg_grade',
        'AVG normalized': 'avg_normalized',
        Evaluators: 'evaluator_table',
        'Feedback editor': 'feedback_editor.name_table',
        ERC: 'erc',
        'F.A.': 'status.feedback_approved',
        Traces: 'traces.length',
      },
      actions: {
      },
      selectedItems: [],
      loading: true,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      fields: 'modals/tableSettingsFields',
      areas: 'areas/areas',
      filters: 'filters/filters',
    }),
  },
  watch: {
    convo() {
      if (this.convo.convo_type_id == 1) {
        this.loadField('all_applicants_senior')
      } else {
        this.loadField('all_applicants')
      }
    },
  },
  async mounted() {
    if (this.convo) {
      if (this.convo.convo_type_id == 1) {
        this.loadField('all_applicants_senior')
      } else {
        this.loadField('all_applicants')
      }
    }
    this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: this.status }, ...this.filters })
    this.loading = false

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async loadField(table) {
      await this.$store.dispatch('modals/fetchUserFields', table)
      if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
        await this.$store.dispatch('modals/fetchFields', {
          fields: table === 'all_applicants_senior' ? this.defaultFields.filter(e => e.name !== 'Applicant ID') : this.defaultFields,
          table,
        })
      }
    },
    customFilter(option, query) {
      // Perform case-insensitive and accent-insensitive matching
      return this.applicants.filter(e => e.full_name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(query))
    },
    applyActions() {
      if (this.selectedItems.length > 0) {
        Vue.swal({
          title: 'Perform the following actions on the selected applicants?',
          html: this.getActionsString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
        }).then(result => {
          if (result.isConfirmed) {
            const itemIds = this.selectedItems.map(el => el.user_id)
            this.$store.dispatch('users/changeStatus', { actions: this.actions, itemIds, convoId: this.convo.id }).then(() => {
              this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: this.status } })
              this.$store.dispatch('modals/toggleInfoItemSheet', false)
              if (this.actions.moveRound == 12) {
                Vue.swal('Applicants upgraded to Researchers successfully', '', 'success')
              } else {
                this.$toastr.success('', 'Actions applied successfully')
              }
            })
          }
        })
      } else {
        this.$toastr.error('', 'No items selected!')
      }
    },
    getActionsString() {
      let str = ''

      if (this.actions.moveRoundZero) {
        str += '<p class="text-success">Move the selected applicants to Round 0</p>'
      }

      if (this.actions.moveRoundOne) {
        str += '<p class="text-success">Move the selected applicants to Round 1</p>'
      }

      if (this.actions.moveRound == 12) {
        str += '<p class="text-success">Mark applicants as position accepted and transform them in ICREA\'s</p>'
      }

      return str
    },
    async appliedFilters() {
      this.loading = true
      await this.$store.dispatch('convo/fetchApplicants', { id: this.$route.params.id, status: { value: this.status }, ...this.filters })
      this.loading = false
    },
    saveSelectedItems(items) {
      this.selectedItems = items
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.candidate && search.candidate !== '' && search.candidate.length > 2) {
        search.candidate = search.candidate.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      }

      await this.$store.dispatch(store, search)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
